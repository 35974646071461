import { FC } from 'react';
import styles from 'components/login/login.module.css';
import Link from 'next/link';
import Image from 'next/image';

const Login: FC = function Login() {
  return (
    <main>
      <div className={styles.wrapper}>
        <div className={styles.loginWrapper}>
          <div className={styles.logo}>
            <Link href="/" passHref>
              <Image src="/tce-logo.png" alt="UTK Tickle Logo" />
            </Link>
          </div>

          <div className={styles.lButton}>
            <button type="button" className={styles.login}>
              Log in as Student
            </button>
          </div>
          <div className={styles.lButton}>
            <button type="button" className={styles.login}>
              Log in as Administrator
            </button>
          </div>
        </div>
      </div>
    </main>
  );
};

export default Login;
