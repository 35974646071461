import { FC } from 'react';
import Login from 'components/login';
import Head from 'next/head';

const LoginPage: FC = function LoginPage() {
  return (
    <div>
      <Head>
        <title>Log In | Scheduler</title>
      </Head>
      <Login />
    </div>
  );
};

export default LoginPage;
